<template>
    <div>
      <!-- Start hero #1-->
      <section class="hero hero-mailchimp bg-primary-alt">
        <div class="container">
          <div class="hero-cotainer">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="hero-content">
                  <h1 class="hero-headline">{{ naskah.judul_naskah || 'Judul tidak ditemukan' }}</h1>
                  <div class="hero-bio">
                    <strong>Kepengarangan :</strong> {{ naskah.author || '-' }}<br />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6"><img class="img-fluid float-right" :src="naskah.cover_url"
                  :alt="naskah.judul_naskah" /></div>
            </div>
            <!-- End .row-->
          </div>
          <!-- End .hero-cotainer-->
        </div>
        <!-- End .container-->
        <div class="divider-shape-bottom"><svg viewBox="0 0 1920 250" xmlns="http://www.w3.org/2000/svg"
            style="background: #2D36A1" width="100%" preserveAspectRatio="none">
            <path fill="rgb(244, 246, 249, 0.02)"
              d="M1920 250H0V0s126.707 78.536 349.975 80.05c177.852 1.203 362.805-63.874 553.803-63.874 290.517 0 383.458 57.712 603.992 61.408 220.527 3.696 278.059-61.408 412.23-17.239">
            </path>
            <path fill="rgb(244, 246, 249, 0.08)"
              d="M1920 144s-467.917 116.857-1027.243-17.294C369.986 1.322 0 45.578 0 45.578V250h1920V144z"></path>
            <path fill="#FFFFFF"
              d="M0 195.553s208.547-75.581 701.325-20.768c376.707 41.908 520.834-67.962 722.545-67.962 222.926 0 311.553 83.523 496.129 86.394V250H0v-54.447z">
            </path>
          </svg>
        </div>
      </section>
      <!-- End #hero-->
  
      <section class="about" id="about">
        <div class="container">
          <div class="row align-items-center h-100">
            <div class="col-12 col-md-12 col-lg-12">
              <div class="heading">
                <h2 class="heading-title">Sinopsis Buku</h2>
              </div>
              <div class="row about-list">
                <div class="col-12">
                  <p class="sinopsis-naskah">
                    {{ naskah.deskripsi }}
                  </p>
                </div>

              </div>
            </div>
          </div>
          <!-- End .row  -->
        </div>
        <!-- End .container  -->
      </section>

      <!-- 
        CTA #1
        =============================================  
        -->
      <section class="cta pt-0 pb-0" id="action">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <div class="action-container">
                <div class="row align-items-center h-100">
                  <div class="col-12 col-md-4 col-lg-4">
                    <div class="cta-cover"><img class="img-fluid" width="350px" :src="naskah.cover_url" :alt="naskah.judul_naskah" /></div>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8">
                    <h3>Penasaran Isi Lengkapnya? <br/> Beli Sekarang. Rp. {{ naskah.harga }}</h3>
                    <div class="row mt-4">
                      <div class="col-12">
                        <h4>Isi Formulir Untuk Pemesanan</h4>
                        <form @submit.prevent="submitForm">
                          <div class="form-group">
                            <label for="nama">Nama</label>
                            <input type="text" v-model="form.nama" class="form-control" id="nama" required>
                          </div>
                          <div class="form-group">
                            <label for="email">Email</label>
                            <input type="email" v-model="form.email" class="form-control" id="email" required>
                          </div>
                          <button type="submit" class="btn btn-primary mt-2">Beli</button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <!-- End .col-md-12-->
                </div>
                <!-- End .row-->
              </div>
            </div>
          </div>
        </div>
        <!-- End .container-->
      </section>
      <!-- End #cta-->
  
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
    components: {
  
    },
    data() {
      return {
        loading_data: false,
        naskah: [],
        form: {
          nama: '',
          email: '',
        }
      }
    },
  
    methods: {
        async landingAffiliate() {
            this.loading_data = true
            const useraff = this.$route.params.useraff;
            const link = this.$route.params.link

            try {
                const response = await axios.get(`https://my.qrcbn.com/api/${useraff}/${link}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                this.naskah = response.data.data
                console.log(response.data)
            } catch (error) {
                console.error("Gagal mengambil data", error.response ? error.response.data : error.message)
            } finally {
                this.loading_data = false
            }
      },
      async submitForm() {
        console.log("Data yang dikirim:", this.form)
        try {
          const response = await axios.post('https://my.qrcbn.com/api/affiliate/transaksi', {
            reseller_id: this.naskah.id,
            name: this.form.nama,
            email: this.form.email,
          })

          const invoiceUrl = response.data.invoice_url
          console.log(invoiceUrl)
          localStorage.setItem('order_email', this.form.email)
          window.location.href = invoiceUrl;
        } catch (error) {
          console.error("error while submiting form", error.response ? error.response.data : error.message)
        }
      }
    },
  
    mounted() {
      this.landingAffiliate('affiliate_id', 'link_penjualan')
    },
  
    // computed: {
    //   noData() {
    //     return this.naskah == null;
    //   }
    // },
  }
  
  </script>
  
  <style>
  .about-item-icon i{
    font-size: 3em;
  }
  .sinopsis-naskah{
    white-space: break-spaces;
    font-size: 1.1em;
  }
  .market-btn{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  </style>  